import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/general/Header';
import Footer from './components/general/Footer';
import Home from './pages/Home';
import Listing from './pages/Listing';
import AdminDashboard from './pages/AdminDashboard';

const App = () => (
	<Router>
		<div className="min-h-screen bg-gray-900 text-white flex flex-col">
			<Header />
			<div className="flex-grow">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/moviedetails" element={<Listing />} />
					<Route path="/admin" element={<AdminDashboard />} />
				</Routes>
			</div>
			<Footer />
		</div>
	</Router>
);

export default App;
