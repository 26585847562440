// src/utils/LanguageFlags.jsx

import React from 'react';

/**
 * Converts language code to country flag image component
 * @param {string} langCode - ISO 639-1 language code (e.g., 'en', 'fr', 'es')
 * @returns {JSX.Element|null} - Flag image component or null if langCode is not provided
 */
export const getLanguageFlag = (langCode) => {
  if (!langCode) return null;

  // Convert language code to country code for flag
  const countryCode = {
    'en': 'us', // English -> USA
    'es': 'es', // Spanish -> Spain
    'fr': 'fr', // French -> France
    'de': 'de', // German -> Germany
    'it': 'it', // Italian -> Italy
    'ja': 'jp', // Japanese -> Japan
    'ko': 'kr', // Korean -> South Korea
    'zh': 'cn', // Chinese -> China
    'ru': 'ru', // Russian -> Russia
    'pt': 'pt', // Portuguese -> Portugal
    'hi': 'in', // Hindi -> India
    'ar': 'sa', // Arabic -> Saudi Arabia
    'tr': 'tr', // Turkish -> Turkey
    'da': 'dk', // Danish -> Denmark
    'nl': 'nl', // Dutch -> Netherlands
    'fi': 'fi', // Finnish -> Finland
    'el': 'gr', // Greek -> Greece
    'he': 'il', // Hebrew -> Israel
    'hu': 'hu', // Hungarian -> Hungary
    'id': 'id', // Indonesian -> Indonesia
    'is': 'is', // Icelandic -> Iceland
    'no': 'no', // Norwegian -> Norway
    'pl': 'pl', // Polish -> Poland
    'ro': 'ro', // Romanian -> Romania
    'sv': 'se', // Swedish -> Sweden
    'th': 'th', // Thai -> Thailand
    'uk': 'ua', // Ukrainian -> Ukraine
    'vi': 'vn', // Vietnamese -> Vietnam
    'cs': 'cz', // Czech -> Czech Republic
    'fa': 'ir', // Persian/Farsi -> Iran
    'bn': 'bd', // Bengali -> Bangladesh
    'tl': 'ph', // Tagalog -> Philippines
    'ms': 'my', // Malay -> Malaysia
    'sk': 'sk', // Slovak -> Slovakia
    'sr': 'rs', // Serbian -> Serbia
    'bg': 'bg', // Bulgarian -> Bulgaria
    'hr': 'hr', // Croatian -> Croatia
    'af': 'za', // Afrikaans -> South Africa
    'ca': 'es-ct', // Catalan -> Catalonia flag
    'et': 'ee', // Estonian -> Estonia
    'eu': 'es', // Basque -> Spain (with regional flag option if needed)
    'gl': 'es-ga', // Galician -> Galicia flag
    'lv': 'lv', // Latvian -> Latvia
    'lt': 'lt', // Lithuanian -> Lithuania
    'mk': 'mk', // Macedonian -> North Macedonia
    'mt': 'mt', // Maltese -> Malta
    'sl': 'si', // Slovenian -> Slovenia
    'sw': 'tz', // Swahili -> Tanzania
    'ta': 'in', // Tamil -> India (alternatives could be Sri Lanka or Singapore)
    'te': 'in', // Telugu -> India
    'ur': 'pk', // Urdu -> Pakistan
    'cy': 'gb-wls', // Welsh -> Wales flag
    'ga': 'ie', // Irish -> Ireland
    'gd': 'gb-sct', // Scottish Gaelic -> Scotland flag
    'pa': 'in', // Punjabi -> India
    'ml': 'in', // Malayalam -> India
    'mr': 'in', // Marathi -> India
    'my': 'mm', // Burmese -> Myanmar
    'ne': 'np', // Nepali -> Nepal
    'si': 'lk', // Sinhala -> Sri Lanka
    'km': 'kh', // Khmer -> Cambodia
    'lo': 'la', // Lao -> Laos
    'mn': 'mn', // Mongolian -> Mongolia
    'az': 'az', // Azerbaijani -> Azerbaijan
    'hy': 'am', // Armenian -> Armenia
    'ka': 'ge', // Georgian -> Georgia
    'uz': 'uz', // Uzbek -> Uzbekistan
    'kk': 'kz', // Kazakh -> Kazakhstan
  }[langCode.toLowerCase()] || langCode.toLowerCase();

  // Use better quality flag with proper sizing
  return (
    <div className="inline-flex items-center justify-center ml-1">
      <img
        src={`https://flagcdn.com/${countryCode}.svg`}
        alt={langCode.toUpperCase()}
        title={langCode.toUpperCase()}
        className="h-3 w-5 object-cover rounded-sm"
        style={{
          aspectRatio: '4/3',
          objectFit: 'cover'
        }}
      />
    </div>
  );
};

export default {
  getLanguageFlag
};