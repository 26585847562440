import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MovieCard from './MovieCard';
import MovieDetailPanel from './MovieDetailPanel';
import LoadingSpinner from '../common/LoadingSpinner';
import EmptyState from '../common/EmptyState';
import GlobalRegionSelector from './GlobalRegionSelector';
import useMovieData from '../../hooks/useMovieData';

const MovieList = () => {
	const location = useLocation();
	const { movies, loading, searchId } = useMovieData(location.state);
	const [expandedMovieId, setExpandedMovieId] = useState(null);
	
	// Add global region state
	const [selectedRegion, setSelectedRegion] = useState('US');
	const [availableRegions, setAvailableRegions] = useState([]);

	// Handler for selecting a movie
	const handleSelectMovie = (movieId) => {
		// Toggle the expanded movie - if already expanded, close it
		setExpandedMovieId(expandedMovieId === movieId ? null : movieId);
	};

	// Collect all available regions from all movies
	useEffect(() => {
		if (movies.length > 0) {
			const regions = new Set();
			
			movies.forEach(movie => {
				if (movie.watch_providers) {
					Object.keys(movie.watch_providers).forEach(region => {
						regions.add(region);
					});
				}
			});
			
			const regionArray = Array.from(regions);
			setAvailableRegions(regionArray);
			
			// Default to US if available, otherwise use the first available region
			if (regionArray.includes('US')) {
				setSelectedRegion('US');
			} else if (regionArray.length > 0) {
				setSelectedRegion(regionArray[0]);
			}
		}
	}, [movies]);

	// Handler for selecting a region
	const handleSelectRegion = (region) => {
		setSelectedRegion(region);
	};

	if (loading) {
		return <LoadingSpinner />;
	}

	// Find the expanded movie if there is one
	const expandedMovie = expandedMovieId ? movies.find(movie => movie.id === expandedMovieId) : null;

	return (
		<div className="my-8">
			<div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
				<h2 className="text-xl font-semibold text-gray-200">RECOMMENDATIONS</h2>
				
				{/* Display global region selector if we have regions */}
				{availableRegions.length > 0 && (
					<GlobalRegionSelector
						selectedRegion={selectedRegion}
						availableRegions={availableRegions}
						onSelectRegion={handleSelectRegion}
					/>
				)}
			</div>

			<div className="space-y-6">
				{movies.length === 0 && !loading && (
					<EmptyState
						title="No matching movies found"
						message="Try adjusting your search query or try a different one."
					/>
				)}

				{/* Movie cards and expanded details */}
				{movies.map((movie) => (
					<div key={movie.id} className="space-y-4">
						{/* Only show movie card if it's not expanded */}
						{expandedMovieId !== movie.id && (
							<MovieCard
								movie={movie}
								isSelected={false}
								onClick={() => handleSelectMovie(movie.id)}
								promptId={searchId}
								rank={movie.rank}
								selectedRegion={selectedRegion} // Pass the global selected region
							/>
						)}

						{/* Expanded details panel - only for this movie when expanded */}
						{expandedMovieId === movie.id && (
							<div className="transition-all duration-300 ease-out">
								<MovieDetailPanel
									movie={expandedMovie}
									onClose={() => setExpandedMovieId(null)}
									promptId={searchId}
									rank={expandedMovie.rank}
									selectedRegion={selectedRegion} // Pass the global selected region
								/>
							</div>
						)}
					</div>
				))}
			</div>

			{/* CSS for animations */}
			<style>
				{`
          @keyframes expandIn {
            from {
              opacity: 0;
              transform: scaleY(0.7);
            }
            to {
              opacity: 1;
              transform: scaleY(1);
            }
          }
        `}
			</style>
		</div>
	);
};

export default MovieList;