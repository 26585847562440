// src/components/admin/AdminFooter.jsx
import React from 'react';

const AdminFooter = () => (
	<footer className="bg-gray-900 text-white text-center py-3 border-t border-gray-800">
		<div className="container mx-auto px-4">
			<p className="mb-0">© 2025 FlickScout. All rights reserved.</p>
		</div>
	</footer>
);

export default AdminFooter;