// src/pages/AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../components/admin/AdminHeader';
import AdminSidebar from '../components/admin/AdminSidebar';
import AdminFooter from '../components/admin/AdminFooter';
import AdminLogin from '../components/admin/AdminLogin';
import MoviesModal from '../components/admin/MoviesModal';
import MoviesSearchesTable from '../components/admin/MoviesSearchesTable';
import { authService, adminService } from '../services/api';

const AdminDashboard = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [moviesSearches, setMoviesSearches] = useState([]);
    // Set default sorting by "created_at" in descending order.
    const [sortColumn, setSortColumn] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');
    const [searchQuery, setSearchQuery] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMovies, setModalMovies] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalError, setModalError] = useState('');

    const navigate = useNavigate();

    // On mount, check if admin is logged in.
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const isAdmin = await authService.isAdmin();
                if (isAdmin) {
                    setLoggedIn(true);
                    fetchMoviesSearches();
                }
            } catch (error) {
                console.error("Admin authentication error:", error);
                setLoggedIn(false);
            }
        };
        
        checkAuth();
    }, []);

    // Handle successful login
    const handleLoginSuccess = () => {
        setLoggedIn(true);
        fetchMoviesSearches();
    };

    // Handle logout
    const handleLogout = () => {
        authService.logout();
        setLoggedIn(false);
    };

    // Fetch data from movies_searches endpoint.
    const fetchMoviesSearches = async () => {
        try {
            const data = await adminService.getMovieSearches();
            setMoviesSearches(data);
        } catch (error) {
            console.error('Error fetching movies searches data:', error);
        }
    };

    // Sort and filter the moviesSearches data.
    const sortedMoviesSearches = () => {
        // Ensure filteredData is always an array
        let filteredData = Array.isArray(moviesSearches) ? moviesSearches : [];
        
        if (searchQuery && filteredData.length > 0) {
            filteredData = filteredData.filter((item) =>
                (item?.transcription &&
                    item.transcription.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item?.improved_transcription &&
                    item.improved_transcription.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        if (sortColumn && filteredData.length > 0) {
            filteredData = [...filteredData].sort((a, b) => {
                let aVal = a[sortColumn];
                let bVal = b[sortColumn];

                // Convert date values for proper comparison.
                if (sortColumn === 'created_at') {
                    aVal = new Date(aVal);
                    bVal = new Date(bVal);
                }

                if (aVal < bVal) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (aVal > bVal) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredData;
    };

    // Handler to change sort column and toggle sort order.
    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    // Open modal and fetch movie details for the given movies_ids.
    const openMoviesModal = async (searchId, moviesIdsValue) => {
        setModalVisible(true);
        setModalMovies([]);
        setModalError('');
        setModalLoading(true);
        try {
            // If moviesIdsValue is an array, use it; otherwise split the string.
            const movieIds = Array.isArray(moviesIdsValue)
                ? moviesIdsValue
                : moviesIdsValue.split(',').map(id => id.trim()).filter(id => id);

            const movies = await Promise.all(
                movieIds.map(async (movieId) => {
                    try {
                        // Fetch movie details
                        const movieData = await adminService.getMovieById(movieId);
                        
                        // Fetch the score
                        const scoreData = await adminService.getMovieScore(searchId, movieId);
                        
                        // Combine movie details with its score
                        return { ...movieData, score: scoreData.score };
                    } catch (error) {
                        console.error(`Error fetching data for movie ${movieId}:`, error);
                        // Return fallback data for this movie
                        return { 
                            id: movieId, 
                            original_title: `Movie ${movieId} (not found)`,
                            score: 0 
                        };
                    }
                })
            );
            setModalMovies(movies);
        } catch (error) {
            console.error(error);
            setModalError('Error fetching movies data');
        } finally {
            setModalLoading(false);
        }
    };

    // If not logged in, show the login form.
    if (!loggedIn) {
        return <AdminLogin onLoginSuccess={handleLoginSuccess} />;
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-900 text-white">
            <AdminHeader onLogout={handleLogout} />
            <div className="flex flex-1 overflow-hidden">
                <AdminSidebar />
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900 p-6">
                    <div className="container mx-auto">
                        <h3 id="movies-searches" className="text-2xl font-bold mb-6 text-white">Movies Searches</h3>
                        
                        {/* Search input */}
                        <div className="mb-6 relative">
                            <input
                                type="text"
                                placeholder="Search transcription..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full bg-gray-800 border border-gray-700 rounded-lg py-2 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-purple-500"
                            />
                            <button className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-purple-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        </div>
                        
                        {/* Table or empty state */}
                        {sortedMoviesSearches().length > 0 ? (
                            <MoviesSearchesTable
                                data={sortedMoviesSearches()}
                                sortColumn={sortColumn}
                                sortOrder={sortOrder}
                                handleSort={handleSort}
                                openMoviesModal={openMoviesModal}
                            />
                        ) : (
                            <div className="bg-gray-800 rounded-lg p-8 text-center border border-gray-700">
                                <p className="text-gray-400">No movie searches data available.</p>
                            </div>
                        )}
                    </div>
                </main>
            </div>
            <AdminFooter />
            
            {/* Movies modal */}
            <MoviesModal
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                loading={modalLoading}
                error={modalError}
                movies={modalMovies}
            />
        </div>
    );
};

export default AdminDashboard;