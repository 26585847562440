import React from 'react';

// More detailed star SVG
const StarSVG = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className={className}
		width="28"
		height="28"
	>
		<path d="M12 0L14.3 8.3L23 9.3L17 15.2L18.5 23.5L12 19.3L5.5 23.5L7 15.2L1 9.3L9.7 8.3L12 0Z" />
	</svg>
);

const Header = () => {
	return (
		<div className="text-center mb-4 mt-12 px-4 sm:px-8">
			{/* AI-Powered Banner with Stars */}
			<div className="flex justify-center mb-6">
				<div className="bg-gray-800 rounded-full px-8 py-3 inline-flex items-center relative">
					{/* Left Star Group - Multiple Animated Stars */}
					<div className="absolute -left-4 -top-2">
						<div className="relative w-12 h-12">
							{/* Main star */}
							<div className="absolute inset-0 flex items-center justify-center text-yellow-400 animate-pulse-subtle">
								<StarSVG className="animate-spin-slow" />
							</div>
							{/* Smaller satellite star */}
							<div className="absolute top-1 right-0 text-yellow-400 opacity-70 scale-50">
								<StarSVG className="animate-spin-slow-reverse" />
							</div>
							{/* Sparkle effects */}
							<div className="absolute bottom-1 left-1 w-1 h-1 bg-yellow-400 rounded-full animate-ping"></div>
						</div>
					</div>

					{/* Banner Text - Now Clickable */}
					<a
						href="/"
						className="text-gray-200 font-semibold tracking-wide text-sm sm:text-lg px-2 hover:text-white transition-colors duration-200"
					>
						AI-POWERED MOVIE RECOMMENDATIONS
					</a>

					{/* Right Star Group - Multiple Animated Stars */}
					<div className="absolute -right-4 -top-2">
						<div className="relative w-12 h-12">
							{/* Main star */}
							<div className="absolute inset-0 flex items-center justify-center text-yellow-400 animate-pulse-subtle">
								<StarSVG className="animate-spin-slow-reverse" />
							</div>
							{/* Smaller satellite star */}
							<div className="absolute bottom-1 left-0 text-yellow-400 opacity-70 scale-50">
								<StarSVG className="animate-spin-slow" />
							</div>
							{/* Sparkle effects */}
							<div className="absolute top-1 right-1 w-1 h-1 bg-yellow-400 rounded-full animate-ping"></div>
						</div>
					</div>
				</div>
			</div>

			{/* Main Heading */}
			<h1 className="text-5xl font-bold mb-4 mt-8">Stop wasting your life browsing Netflix.</h1>

			{/* Subheading */}
			<p className="text-2xl text-gray-300">
				Get a movie recommendation for exactly what you're in<br />the mood for, and where you can watch it.
			</p>
		</div>
	);
};

export default Header;