import React from 'react';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-gray-800 rounded-lg p-8 flex flex-col items-start border border-gray-700 shadow-lg h-full transition-all duration-300 ease-in-out hover:shadow-xl hover:border-purple-500 hover:-translate-y-1">
      <div className="bg-gray-500 w-12 h-12 rounded-full flex items-center justify-center mb-6 transition-transform duration-300 ease-in-out hover:scale-110">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-300 text-left">{description}</p>
    </div>
  );
};

export default FeatureCard;
