// src/components/admin/MoviesModal.jsx
import React from 'react';

const MoviesModal = ({ show, onHide, loading, error, movies }) => {
    if (!show) return null;

    return (
        <>
            {/* Modal backdrop */}
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center p-4"
                onClick={onHide}
            >
                {/* Modal content */}
                <div
                    className="bg-gray-800 rounded-lg shadow-lg max-w-md w-full max-h-[80vh] overflow-hidden z-50"
                    onClick={e => e.stopPropagation()}
                >
                    {/* Modal header */}
                    <div className="border-b border-gray-700 p-4 flex justify-between items-center">
                        <h3 className="text-xl font-semibold text-white">Movies</h3>
                        <button
                            onClick={onHide}
                            className="text-gray-400 hover:text-white focus:outline-none"
                        >
                            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>

                    {/* Modal body */}
                    <div className="p-4 overflow-y-auto max-h-[60vh]">
                        {loading ? (
                            <div className="flex justify-center items-center py-8">
                                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
                            </div>
                        ) : error ? (
                            <p className="text-red-500">{error}</p>
                        ) : (
                            <ul className="space-y-2">
                                {movies.map((movie) => (
                                    <li key={movie.id} className="p-2 hover:bg-gray-700 rounded transition-colors">
                                        <span className="flex items-center justify-between">
                                            <span>{movie.original_title}</span>
                                            <span>
                                                {movie.score === 1 ? "👍" : movie.score === 0 ? "👎" : ""}
                                            </span>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* Modal footer */}
                    <div className="border-t border-gray-700 p-4 flex justify-end">
                        <button
                            onClick={onHide}
                            className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded transition-colors"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MoviesModal;