// components/listing/MoviePoster.jsx
import React from 'react';

const MoviePoster = ({ posterUrl, title, onClick }) => {
	return (
		<div
			onClick={onClick}
			className="w-full sm:w-56 h-48 sm:h-80 bg-gray-700 flex-shrink-0 cursor-pointer relative group"
		>
			{posterUrl ? (
				<>
					<img
						src={posterUrl}
						alt={title}
						className="w-full h-full object-cover transition-opacity group-hover:opacity-80"
					/>
					<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all opacity-0 group-hover:opacity-100">
						<span className="text-white bg-purple-600 bg-opacity-90 px-3 py-1.5 rounded-full text-sm font-medium">
							View Details
						</span>
					</div>
				</>
			) : (
				<div className="w-full h-full flex items-center justify-center bg-gray-800 text-gray-500 cursor-pointer hover:bg-gray-700 transition-colors">
					<span>No poster available</span>
					<span className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-40 transition-all opacity-0 hover:opacity-100">
						<span className="text-white bg-purple-600 bg-opacity-90 px-3 py-1.5 rounded-full text-sm font-medium">
							View Details
						</span>
					</span>
				</div>
			)}
		</div>
	);
};

export default MoviePoster;