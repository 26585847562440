import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className="bg-gray-800 py-8 w-full border-t border-gray-700 mt-12">
			<div className="container mx-auto px-4">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<div className="mb-4 md:mb-0 text-center md:text-left">
						<h3 className="text-xl font-bold text-white">FlickScout</h3>
						<p className="text-gray-300 mt-1">Find your perfect movie match</p>
					</div>

					<div className="flex space-x-6">
						<a href="#" className="text-gray-400 hover:text-white">About</a>
						<a href="#" className="text-gray-400 hover:text-white">Privacy</a>
						<a href="#" className="text-gray-400 hover:text-white">Terms</a>
						<a href="#" className="text-gray-400 hover:text-white">Contact</a>
					</div>
				</div>
				<div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
					<p>&copy; {new Date().getFullYear()} FlickScout. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;