// components/listing/StreamingBadge.jsx

import React from 'react';

const StreamingBadge = ({ provider }) => {
	// Check if we have a provider object from TMDB or just a platform name string
	const isProviderObject = typeof provider === 'object';
	
	// Extract the name and logo path
	const providerName = isProviderObject ? provider.provider_name : provider;
	const logoPath = isProviderObject ? provider.logo_path : null;
	const providerType = isProviderObject && provider.type ? provider.type : '';

	// Shorten platform name on mobile if needed
	const displayName = providerName.length > 6
		? window.innerWidth < 640 ? providerName.split(' ')[0] : providerName
		: providerName;

	// Badge styles based on provider type
	const typeBadgeStyles = {
		'Subscription': 'bg-blue-600',
		'Free': 'bg-green-600',
		'Ads': 'bg-yellow-600',
		'Rent': 'bg-purple-600',
		'Buy': 'bg-red-600',
		'': 'bg-gray-600'
	};

	const typeBgColor = typeBadgeStyles[providerType] || typeBadgeStyles[''];

	return (
		<div className="relative group">
			<div className="px-2 sm:px-3 py-1 sm:py-1.5 rounded-lg bg-gray-700 
				text-white flex items-center transition-colors duration-200 text-xs sm:text-sm">
				
				{logoPath ? (
					// Show actual logo if available
					<img 
						src={`https://image.tmdb.org/t/p/original${logoPath}`} 
						alt={providerName}
						className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2 rounded-sm object-cover"
					/>
				) : (
					// Fallback icon if no logo
					<div className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2 rounded-full bg-white bg-opacity-20 flex items-center justify-center text-xs">
						{providerName.charAt(0)}
					</div>
				)}
				
				<span>{displayName}</span>
				
				{/* Show type badge on hover */}
				{providerType && (
					<div className="absolute -top-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
						<span className={`text-xs px-1.5 py-0.5 rounded ${typeBgColor} text-white whitespace-nowrap`}>
							{providerType}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default StreamingBadge;