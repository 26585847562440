import React, { useState } from 'react';

const AdminHeader = ({ onLogout }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<nav className="bg-gray-900 text-white border-b border-gray-800">
			<div className="container mx-auto px-4">
				<div className="flex justify-between items-center py-3">
					{/* Brand */}
					<div className="text-xl font-bold">Admin Dashboard</div>

					{/* Mobile menu button */}
					<button
						className="lg:hidden flex items-center px-3 py-2 border rounded border-gray-700 hover:border-white"
						onClick={() => setIsMenuOpen(!isMenuOpen)}
					>
						<svg className="h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
						</svg>
					</button>

					{/* Desktop menu */}
					<div className="hidden lg:flex items-center justify-between w-full lg:w-auto ml-6">
						<div className="flex space-x-4">
							<button className="py-2 px-3 hover:bg-gray-800 rounded text-left">Dashboard</button>
							<button className="py-2 px-3 hover:bg-gray-800 rounded text-left">Settings</button>
						</div>
						<button
							onClick={onLogout}
							className="ml-6 py-1 px-3 border border-white rounded hover:bg-white hover:text-gray-900 transition-colors"
						>
							Logout
						</button>
					</div>
				</div>

				{/* Mobile menu */}
				{isMenuOpen && (
					<div className="lg:hidden py-2 border-t border-gray-800">
						<button className="block w-full py-2 hover:bg-gray-800 rounded px-3 text-left">Dashboard</button>
						<button className="block w-full py-2 hover:bg-gray-800 rounded px-3 text-left">Settings</button>
						<div className="py-2">
							<button
								onClick={onLogout}
								className="w-full text-left py-2 px-3 hover:bg-gray-800 rounded"
							>
								Logout
							</button>
						</div>
					</div>
				)}
			</div>
		</nav>
	);
};

export default AdminHeader;