// components/listing/MovieDetailHeader.jsx
import React from 'react';
import RatingBadge from './RatingBadge';
import { getLanguageFlag } from '../../utils/LanguageFlags';

const MovieDetailHeader = ({ movie, onClose }) => {
	return (
		<div className="relative h-40 sm:h-60 bg-gray-700">
			<img
				src={
					movie.backdropUrl ||
					`https://placehold.co/1200x400/222222/9B89EF.png?text=${encodeURIComponent(
						movie.title
					)}`
				}
				alt={movie.title}
				className="absolute top-0 left-0 w-full h-full object-cover opacity-50"
			/>

			{/* Gradient overlay */}
			<div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent"></div>

			{/* Close button aligned at the top right */}
			<button
				onClick={onClose}
				className="absolute top-4 right-4 bg-gray-700 p-2 rounded-full hover:bg-gray-600 transition-colors duration-200 flex-shrink-0 z-10"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5 sm:h-6 sm:w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button>

			{/* Movie info overlay at the bottom */}
			<div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6">
				<div className="flex items-start justify-between">
					<div className="max-w-[80%]">
						<h2 className="text-xl sm:text-3xl font-bold truncate flex items-center">
							{movie.title}{' '}
							<span className="text-lg sm:text-2xl">({movie.year})</span>
							{movie.original_language && (
								<span className="ml-2">
									{getLanguageFlag(movie.original_language)}
								</span>
							)}
						</h2>
						<div className="flex flex-wrap items-center mt-2 gap-2">
							<RatingBadge rating={movie.rating} />
							<span className="text-gray-300 text-sm sm:text-base">
								{movie.duration}
							</span>
							{movie.vote_count && (
								<span className="text-gray-400 text-xs">
									({movie.vote_count} votes)
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MovieDetailHeader;