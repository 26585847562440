// components/common/LoadingSpinner.jsx
import React from 'react';

const LoadingSpinner = () => {
	return (
		<div className="my-12 flex justify-center items-center h-64">
			<div className="relative w-24 h-24">
				<div className="absolute top-0 left-0 w-full h-full border-4 border-gray-700 border-t-purple-500 rounded-full animate-spin"></div>
			</div>
		</div>
	);
};

export default LoadingSpinner;