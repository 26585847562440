import React from 'react';
import SearchBar from '../components/home/search/SearchBar';
import HowItWorks from '../components/home/HowItWorks';

const Home = () => {
	return (
		<>
			<title>FlickScout.com - Describe a movie, watch it.</title>
			<meta name="description" content="Describe the movie you want to watch and FlickScout will instantly recommend the perfect match tailored to your description!" />
			
			<div className="container mx-auto px-4 py-12">
				<SearchBar />
				<HowItWorks />
			</div>
		</>
	);
};

export default Home;