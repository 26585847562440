// hooks/useAudioRecorder.js

import { useState, useRef } from 'react';
import RecordRTC from 'recordrtc';

const useAudioRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [permissionError, setPermissionError] = useState(null);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);

    const startRecording = async () => {
        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
            // Use RecordRTC with MP3 configuration for minimal compression
            recorderRef.current = RecordRTC(streamRef.current, {
                type: 'audio',
                mimeType: 'audio/mp3',
                recorderType: RecordRTC.StereoAudioRecorder,
                audioBitsPerSecond: 8000, // Lower bitrate for minimal quality
                desiredSampRate: 8000,    // Telephone-quality sample rate
                numberOfAudioChannels: 1  // Mono
            });

            recorderRef.current.startRecording();
            setIsRecording(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
            setPermissionError('Microphone permission is required to record audio.');
        }
    };

    const stopRecording = () => {
        return new Promise((resolve, reject) => {
            if (recorderRef.current && isRecording) {
                recorderRef.current.stopRecording(() => {
                    
                    // Get MP3 Blob
                    const audioBlob = recorderRef.current.getBlob();

                    // Clean up
                    streamRef.current.getTracks().forEach((track) => track.stop());
                    setIsRecording(false);

                    resolve(audioBlob);
                });
            } else {
                reject(new Error('Not recording'));
            }
        });
    };

    return { isRecording, startRecording, stopRecording, permissionError };
};

export default useAudioRecorder;