import React, { useState, useEffect, useRef } from 'react';
import { Mic, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useAudioRecorder from '../../../hooks/useAudioRecorder';

const SimplifiedSearchBar = () => {
	const [query, setQuery] = useState('');
	const [searchMode, setSearchMode] = useState('voice');
	const [isListening, setIsListening] = useState(false);
	const [placeholder, setPlaceholder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	
	// Use the audio recorder hook
	const {
		isRecording,
		startRecording,
		stopRecording,
		permissionError,
	} = useAudioRecorder();

	// API endpoint (relative path for security)
	const apiUrl = '/api';

	// Typing effect implementation
	const suggestions = [
		"a sci-fi movie about time travel with a female lead",
		"a comedy with Ryan Reynolds and a talking animal",
		"an action movie with car chases set in Tokyo",
		"a romantic drama set in Paris during autumn",
		"a thriller about artificial intelligence gone wrong"
	];

	const suggestionIndex = useRef(0);
	const charIndex = useRef(0);
	const isDeleting = useRef(false);
	const typingSpeed = useRef(40);
	const typingTimer = useRef(null);

	// Start typing animation
	useEffect(() => {
		const typingEffect = () => {
			const currentSuggestion = suggestions[suggestionIndex.current];

			if (isDeleting.current) {
				setPlaceholder(currentSuggestion.substring(0, charIndex.current));
				charIndex.current -= 1;

				if (charIndex.current < 0) {
					isDeleting.current = false;
					suggestionIndex.current = (suggestionIndex.current + 1) % suggestions.length;
					charIndex.current = 0;
					typingTimer.current = setTimeout(typingEffect, 300);
					return;
				}
			} else {
				setPlaceholder(currentSuggestion.substring(0, charIndex.current));
				charIndex.current += 1;

				if (charIndex.current > currentSuggestion.length) {
					isDeleting.current = true;
					typingTimer.current = setTimeout(typingEffect, 1000);
					return;
				}
			}

			typingTimer.current = setTimeout(typingEffect, typingSpeed.current);
		};

		typingTimer.current = setTimeout(typingEffect, 100);

		return () => {
			if (typingTimer.current) clearTimeout(typingTimer.current);
		};
	}, [suggestions]);

	/**
	 * Handles the record button click
	 * Starts/stops recording and sends audio data
	 */
	const handleVoiceClick = async () => {
		if (!isRecording) {
			setIsListening(true);
			startRecording();
		} else {
			try {
				const audioBlob = await stopRecording();
				if (audioBlob) {
					uploadAudio(audioBlob);
				} else {
					console.error('No audio data available.');
					setIsListening(false);
				}
			} catch (error) {
				console.error('Error stopping recording:', error);
				setIsListening(false);
			}
		}

		// Warm up the API asynchronously
		fetch(`${apiUrl}/warmup`)
			.then(response => {
				if (!response.ok) {
					throw new Error(`Failed to warm up API: ${response.status}`);
				}
				return response.text();
			})
			.then(() => {
				console.log('Warmup endpoint called successfully.');
			})
			.catch((error) => {
				console.error('Failed to call the warmup endpoint:', error);
			});
	};

	/**
	 * Uploads audio data to the API for processing
	 * @param {Blob} audioBlob - The recorded audio as a Blob
	 */
	const uploadAudio = (audioBlob) => {
		setIsLoading(true);
		setIsListening(false);

		// Create a new blob with explicit mp3 type if needed
		const fileToUpload = (audioBlob.type === 'audio/mp3' || audioBlob.type === 'audio/mpeg')
			? audioBlob
			: new Blob([audioBlob], { type: 'audio/mpeg' });

		// Prepare the FormData
		const formData = new FormData();
		
		// Add the file with the specific field name expected by FastAPI
		formData.append('file', fileToUpload, 'audio.mp3');

		// Send the audio file to the movies endpoint
		fetch(`${apiUrl}/movies`, {
			method: 'POST',
			body: formData
		})
			.then((response) => {
				if (!response.ok) {
					return response.text().then(text => {
						throw new Error(`Failed to fetch movies: ${response.status} ${response.statusText} - ${text}`);
					});
				}
				return response.json();
			})
			.then((movieData) => {
				setIsLoading(false);
				// Navigate to the movie details page with the movie data
				navigate('/moviedetails', { state: { movieData } });
			})
			.catch((error) => {
				console.error('Failed to fetch movies:', error);
				setIsLoading(false);
				alert('Sorry, there was an error processing your request. Please try again or use text input instead.');
			});
	};

	/**
	 * Handles text search submission
	 * Sends text query to the API
	 */
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!query.trim()) return;

		setIsLoading(true);
		console.log("Submitting search query:", query);

		// Use URL parameters for text input (avoids multipart form parsing issues)
		fetch(`${apiUrl}/movies?text_input=${encodeURIComponent(query)}`, {
			method: 'POST'
		})
			.then((response) => {
				if (!response.ok) {
					return response.text().then(text => {
						throw new Error(`Failed to fetch movies: ${response.status} ${response.statusText} - ${text}`);
					});
				}
				return response.json();
			})
			.then((movieData) => {
				setIsLoading(false);
				setQuery('');
				// Navigate to the movie details page with the movie data
				navigate('/moviedetails', { state: { movieData } });
			})
			.catch((error) => {
				console.error('Failed to fetch movies:', error);
				setIsLoading(false);
				alert('Sorry, there was an error processing your request. Please try again.');
			});
	};

	// Handle mode toggle
	const toggleMode = () => {
		setSearchMode(searchMode === 'voice' ? 'text' : 'voice');
		// If we were recording, stop the recording when switching modes
		if (isRecording) {
			stopRecording().catch(error => {
				console.error('Error stopping recording on mode switch:', error);
			});
			setIsListening(false);
		}
	};

	return (
		<div className="flex flex-col items-center mb-12 w-full max-w-full px-4">
			{searchMode === 'voice' ? (
				<div className="text-center w-full">
					<button
						onClick={handleVoiceClick}
						disabled={isLoading}
						className={`
							bg-purple-500 hover:bg-purple-550
							text-white px-6 py-3 rounded-full inline-flex items-center justify-center text-lg font-medium 
							transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto
						`}
					>
						<Mic size={20} className={`mr-2 ${isRecording ? 'animate-pulse' : ''}`} />
						{isRecording ? 'Recording... Click to Stop' : 'Search with Voice'}
					</button>
					
					{permissionError && (
						<p className="text-red-500 mt-2 text-sm">{permissionError}</p>
					)}
				</div>
			) : (
				<form onSubmit={handleSubmit} className="w-full">
					<div className="relative">
						<Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
						<input
							type="text"
							placeholder={`Try "${placeholder}"`}
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							className="w-full bg-gray-800 border-2 border-gray-700 rounded-full py-3 pl-10 pr-12 text-white placeholder-gray-400 hover:border-gray-600 focus:border-purple-500 focus:outline-none transition-colors duration-200 text-sm md:text-base"
							autoFocus
							disabled={isLoading}
						/>
						<button
							type="submit"
							disabled={isLoading || !query.trim()}
							className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-gray-700 p-1 rounded hover:bg-purple-500 transition-colors duration-200 disabled:opacity-50 disabled:hover:bg-gray-700"
						>
							{isLoading ? (
								<div className="w-4 h-4 border-2 border-t-transparent border-gray-300 rounded-full animate-spin" />
							) : (
								<Search size={16} className="text-gray-300" />
							)}
						</button>
					</div>
				</form>
			)}

			<button
				onClick={toggleMode}
				className="text-purple-400 hover:text-purple-300 text-sm font-medium mt-4"
				disabled={isLoading}
			>
				{searchMode === 'voice' ? 'Switch to text search' : 'Switch to voice search'}
			</button>

			{/* Full-page loading overlay when search is in progress */}
			{isLoading && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
					<div className="bg-gray-800 rounded-lg p-8 max-w-md w-full mx-4 border border-gray-700 flex flex-col items-center">
						<div className="relative mb-6">
							{/* Animated loading circles */}
							<div className="w-20 h-20 border-4 border-t-purple-500 border-gray-700 rounded-full animate-spin"></div>
							<div className="absolute inset-0 flex items-center justify-center">
								<Search size={24} className="text-purple-500" />
							</div>
						</div>
						<h2 className="text-xl font-bold mb-2 text-white">Searching for movies...</h2>
						<p className="text-gray-400 text-center">Looking for the perfect matches to your query</p>
						{query && <p className="text-purple-400 text-sm mt-4 font-medium">"{query}"</p>}
					</div>
				</div>
			)}

			{/* Voice Recording Modal */}
			{isListening && !isLoading && (
				<div className="fixed inset-0 z-50 flex items-center justify-center">
					<div className="absolute inset-0 bg-black bg-opacity-70 backdrop-filter backdrop-blur-sm"></div>

					<div className="bg-gray-800 p-8 rounded-lg w-full max-w-md mx-4 z-10 relative border border-gray-700">
						<div className="flex justify-center mb-8">
							<div className="relative">
								{/* Animated recording circles */}
								<div className="absolute inset-0 rounded-full border-4 border-purple-500 opacity-20 animate-ping"></div>
								<div className="w-20 h-20 rounded-full bg-gray-700 flex items-center justify-center">
									<div className="w-12 h-12 bg-purple-500 rounded-full flex items-center justify-center animate-pulse">
										<Mic size={24} className="text-white" />
									</div>
								</div>
							</div>
						</div>

						<h2 className="text-xl font-bold text-center mb-4">Listening...</h2>
						<p className="text-gray-400 text-center mb-8">
							Tell me what kind of movie you're looking for
						</p>

						<div className="flex justify-center">
							<button
								onClick={handleVoiceClick}
								className="bg-gray-700 hover:bg-gray-600 text-white px-6 py-2 rounded-full"
							>
								Stop Recording
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default SimplifiedSearchBar;