import React, { useState } from 'react';
import { FaSmile, FaMeh, FaFrown, FaSpinner } from 'react-icons/fa';

const SearchPrompt = ({ query, transcriptionRating, searchId }) => {
	const [ratingSubmitted, setRatingSubmitted] = useState(false);
	const [submissionStatus, setSubmissionStatus] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const apiUrl = '/api';

	// Function to call the API endpoint when a rating button is clicked
	const handleScore = async (score) => {
		if (ratingSubmitted) return; // Prevent multiple submissions

		setRatingSubmitted(true);
		setIsLoading(true);

		try {
			const payload = {
				search_id: Number(searchId), // The ID of the inserted record in movies_search
				score: score
			};

			const response = await fetch(`${apiUrl}/score/transcription`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});
			
			if (response.ok) {
				setSubmissionStatus("Thank you for your feedback!");
			} else {
				setSubmissionStatus("Error submitting rating. Please try again.");
				setRatingSubmitted(false);
			}
		} catch (error) {
			console.error("Error submitting transcription rating:", error);
			setSubmissionStatus("Error submitting rating. Please try again.");
			setRatingSubmitted(false);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="mb-8 mt-4">
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
				<h2 className="text-xl font-semibold text-gray-200">YOUR PROMPT</h2>

				{/* Transcription rating buttons - only show if we have a search ID */}
				{searchId && transcriptionRating !== undefined && (
					<div className="flex items-center mt-2 sm:mt-0">
						<span className="text-gray-200 mr-2 text-sm">RATE TRANSCRIPTION:</span>
						
						{/* Rating buttons - face icons with fixed colors */}
						<div className="flex items-center space-x-3">
							<button
								onClick={() => handleScore(0)}
								disabled={ratingSubmitted || isLoading}
								className={`p-1.5 rounded bg-gray-700 hover:bg-gray-600 
								${ratingSubmitted && transcriptionRating !== 0 ? 'opacity-50 cursor-not-allowed' : ''}
								${ratingSubmitted && transcriptionRating === 0 ? 'ring-2 ring-red-500' : ''}`}
								title="Bad"
							>
								<FaFrown className="w-5 h-5 text-red-500" />
							</button>
							
							<button
								onClick={() => handleScore(1)}
								disabled={ratingSubmitted || isLoading}
								className={`p-1.5 rounded bg-gray-700 hover:bg-gray-600
								${ratingSubmitted && transcriptionRating !== 1 ? 'opacity-50 cursor-not-allowed' : ''}
								${ratingSubmitted && transcriptionRating === 1 ? 'ring-2 ring-yellow-500' : ''}`}
								title="OK"
							>
								<FaMeh className="w-5 h-5 text-yellow-500" />
							</button>
							
							<button
								onClick={() => handleScore(2)}
								disabled={ratingSubmitted || isLoading}
								className={`p-1.5 rounded bg-gray-700 hover:bg-gray-600
								${ratingSubmitted && transcriptionRating !== 2 ? 'opacity-50 cursor-not-allowed' : ''}
								${ratingSubmitted && transcriptionRating === 2 ? 'ring-2 ring-green-500' : ''}`}
								title="Amazing"
							>
								<FaSmile className="w-5 h-5 text-green-500" />
							</button>
							
							{isLoading && (
								<div className="flex items-center">
									<FaSpinner className="animate-spin text-purple-400 ml-1 w-3 h-3" />
								</div>
							)}
							
							{submissionStatus && !isLoading && (
								<span className="text-green-400 text-xs ml-1">{submissionStatus}</span>
							)}
						</div>
					</div>
				)}
			</div>

			{/* Query display */}
			<div className="bg-gray-800 p-4 rounded-lg mt-2 border border-gray-700">
				<p className="text-white text-lg">"{query}"</p>
			</div>
		</div>
	);
};

export default SearchPrompt;