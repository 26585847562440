// pages/Listing.jsx

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MovieList from '../components/listing/MovieList';
import SearchPrompt from '../components/listing/SearchPrompt';

const Listing = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [transcriptionRating, setTranscriptionRating] = useState(3); // Default high rating
	const [searchId, setSearchId] = useState(null);
	const location = useLocation();

	// Extract search query information from router state or localStorage
	useEffect(() => {
		// Try to get data from react-router location state first
		if (location.state && location.state.movieData) {
			const movieData = location.state.movieData;

			// Extract search query if available
			if (movieData.improved_transcription) {
				setSearchQuery(movieData.improved_transcription);
			} else if (movieData.transcription) {
				setSearchQuery(movieData.transcription);
			} else if (movieData.text_input) {
				setSearchQuery(movieData.text_input);
			}

			// Extract transcription rating if available
			if (movieData.transcription_rating !== undefined) {
				setTranscriptionRating(movieData.transcription_rating);
			}

			// Extract search ID if available
			if (movieData.id_search || movieData.id) {
				setSearchId(movieData.id_search || movieData.id);
			}
		} else {
			// Try to get from localStorage if not in location state
			try {
				const savedData = localStorage.getItem('movieSearchResults');
				if (savedData) {
					const parsedData = JSON.parse(savedData);

					// Extract search query
					if (parsedData.improved_transcription) {
						setSearchQuery(parsedData.improved_transcription);
					} else if (parsedData.transcription) {
						setSearchQuery(parsedData.transcription);
					} else if (parsedData.text_input) {
						setSearchQuery(parsedData.text_input);
					}

					// Extract transcription rating
					if (parsedData.transcription_rating !== undefined) {
						setTranscriptionRating(parsedData.transcription_rating);
					}

					// Extract search ID
					if (parsedData.id_search || parsedData.id) {
						setSearchId(parsedData.id_search || parsedData.id);
					}
				}
			} catch (error) {
				console.error('Error parsing saved search data:', error);
			}
		}
	}, [location]);

	return (
		<>
			<title>Listing Page</title>
			<meta name="description" content="Describe the movie you want to watch and FlickScout will instantly recommend the perfect match tailored to your description!" />
			<meta robots="noindex" />

			<div className="min-h-screen bg-gray-900 text-white">
				<div className="container mx-auto px-4 py-8 max-w-screen-xl">
					{/* Search prompt display */}
					<SearchPrompt
						query={searchQuery}
						transcriptionRating={transcriptionRating}
						searchId={searchId}
					/>

					{/* Search again button */}
					<div className="flex justify-center my-10">
						<button
							onClick={() => {
								// Set a flag in sessionStorage to indicate we want to scroll to top
								sessionStorage.setItem('scrollToTop', 'true');
								window.location.href = '/';
							}}
							className="bg-purple-500 hover:bg-purple-550 text-white px-6 py-3 rounded-full text-lg font-medium transition-colors duration-300"
						>
							Search Again
						</button>
					</div>

					{/* Main movie listing */}
					<MovieList />

					{/* Search again button */}
					<div className="flex justify-center my-10">
						<button
							onClick={() => {
								// Set a flag in sessionStorage to indicate we want to scroll to top
								sessionStorage.setItem('scrollToTop', 'true');
								window.location.href = '/';
							}}
							className="bg-purple-500 hover:bg-purple-550 text-white px-6 py-3 rounded-full text-lg font-medium transition-colors duration-300"
						>
							Search Again
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Listing;