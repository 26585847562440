// components/listing/MovieDetailPanel.jsx
import React from 'react';
import MovieDetailHeader from './MovieDetailHeader';
import MovieDetailsGrid from './MovieDetailsGrid';
import MovieFeedback from './MovieFeedback';
import StreamingProviders from './StreamingProviders';

const MovieDetailPanel = ({ movie, onClose, promptId, rank, selectedRegion }) => {
	return (
		<div className="bg-gray-800 rounded-lg overflow-hidden border border-purple-500 mb-8 shadow-lg shadow-purple-500/20">
			{/* Header with backdrop image, title, rating, etc. */}
			<MovieDetailHeader movie={movie} onClose={onClose} />

			{/* Content */}
			<div className="p-4 sm:p-6">
				{/* Movie tagline */}
				{movie.tagline && (
					<h4 className="text-gray-300 italic mt-2 text-xl">"{movie.tagline}"</h4>
				)}

				{/* Synopsis */}
				<p className="text-gray-300 text-sm sm:text-base mb-6 mt-2">
					{movie.fullDescription || movie.description}
				</p>

				{/* Details grid (director, stars, budget, revenue) */}
				<MovieDetailsGrid movie={movie} />

				{/* Streaming options and Feedback Section */}
				<div className="mt-6 sm:mt-8 flex flex-col sm:flex-row justify-between gap-4">
					{/* Streaming options */}
					<div>
						<div className="flex items-center mb-2">
							<h3 className="text-gray-100 text-sm sm:text-base">
								Available On:
							</h3>
						</div>

						{/* Use StreamingProviders component with global selectedRegion */}
						<StreamingProviders
							watchProviders={movie.watch_providers}
							selectedRegion={selectedRegion}
						/>

						{/* Fallback for old data format */}
						{!movie.watch_providers && movie.streamingOn && (
							<div className="flex flex-wrap gap-2 sm:gap-3">
								{movie.streamingOn.length > 0 ? (
									movie.streamingOn.map((platform) => (
										<span key={platform} className="bg-gray-700 text-white text-xs px-2 py-1 rounded">
											{platform}
										</span>
									))
								) : (
									<span className="text-gray-300">Information not available</span>
								)}
							</div>
						)}
					</div>

					{/* Feedback Section using MovieFeedback component */}
					<div className="flex justify-end sm:self-end">
						<MovieFeedback
							movieId={movie.id}
							promptId={promptId}
							rank={rank}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MovieDetailPanel;