import React from 'react';
import { Film, Star, Play } from 'lucide-react';
import FeatureCard from './FeatureCard';

const HowItWorks = () => {
	const features = [
		{
			icon: <Film size={24} className="text-purple-500" />,
			title: "Describe Your Mood",
			description: "Tell us what kind of movie you're looking for using natural language"
		},
		{
			icon: <Star size={24} className="text-purple-500" />,
			title: "Get Recommendations",
			description: "Our AI matches your description with the perfect movies"
		},
		{
			icon: <Play size={24} className="text-purple-500" />,
			title: "Start Watching",
			description: "Find out where to watch your matched movies across streaming platforms"
		}
	];

	return (
		<div className="mb-4">
			<h2 className="text-3xl font-bold text-center mb-6">How It Works</h2>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						icon={feature.icon}
						title={feature.title}
						description={feature.description}
					/>
				))}
			</div>
		</div>
	);
};

export default HowItWorks;
