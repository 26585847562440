// src/components/admin/MoviesSearchesTable.jsx
import React from 'react';

const MoviesSearchesTable = ({ data, sortColumn, sortOrder, handleSort, openMoviesModal }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-800 border border-gray-700 shadow-sm rounded-lg overflow-hidden">
                <thead>
                    <tr className="bg-gray-700">
                        <th
                            onClick={() => handleSort('id')}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-600"
                        >
                            ID {sortColumn === 'id' && (
                                <span className="ml-1">{sortOrder === 'asc' ? '▲' : '▼'}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('created_at')}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-600"
                        >
                            Created At {sortColumn === 'created_at' && (
                                <span className="ml-1">{sortOrder === 'asc' ? '▲' : '▼'}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('transcription')}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-600"
                        >
                            Transcription {sortColumn === 'transcription' && (
                                <span className="ml-1">{sortOrder === 'asc' ? '▲' : '▼'}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('improved_transcription')}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-600"
                        >
                            Improved Transcription {sortColumn === 'improved_transcription' && (
                                <span className="ml-1">{sortOrder === 'asc' ? '▲' : '▼'}</span>
                            )}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                            Movies
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                    {data.map((item) => (
                        <tr key={item.id} className="hover:bg-gray-700">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{item.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {new Date(item.created_at).toLocaleString()}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-300">{item.transcription}</td>
                            <td className="px-6 py-4 text-sm text-gray-300">{item.improved_transcription}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                <button
                                    onClick={() => openMoviesModal(item.id, item.movies_ids)}
                                    className="text-purple-400 hover:text-purple-300 hover:underline focus:outline-none"
                                >
                                    Movies
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MoviesSearchesTable;