// src/components/admin/AdminSidebar.jsx
import React from 'react';

const AdminSidebar = () => {
    return (
        <div className="bg-gray-800 w-64 flex-shrink-0 border-r border-gray-700">
            <div className="p-6">
                <h2 className="text-xl font-bold text-white mb-6">Sidebar</h2>

                <nav>
                    <ul className="space-y-4">
                        <li>
                            <a
                                href="#movies-searches"
                                className="block py-2 px-4 rounded hover:bg-gray-700 text-gray-300 hover:text-white transition-colors"
                            >
                                Movies Searches
                            </a>
                        </li>
                        <li>
                            <a
                                href="#other"
                                className="block py-2 px-4 rounded hover:bg-gray-700 text-gray-300 hover:text-white transition-colors"
                            >
                                Other
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AdminSidebar;