import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

// Cookie helper functions
const setCookie = (name, value, days = 30) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return "";
};

// Simplified country flag retrieval
const getCountryFlag = (countryCode) => {
    if (!countryCode) return null;

    return (
        <div className="inline-flex items-center justify-center">
            <img
                src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
                alt={countryCode}
                title={countryCode}
                className="h-4 w-6 object-cover rounded-sm"
                style={{
                    aspectRatio: '4/3',
                    objectFit: 'cover'
                }}
            />
        </div>
    );
};

const GlobalRegionSelector = ({
    selectedRegion,
    availableRegions,
    onSelectRegion
}) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const COOKIE_NAME = 'preferred_region';

    // Get user's location or use stored preference on component mount
    useEffect(() => {
        const selectRegion = async () => {
            // Only proceed if we have available regions
            if (availableRegions.length === 0) return;
            
            try {
                setLoading(true);
                
                // First check if there's a stored cookie preference
                const storedRegion = getCookie(COOKIE_NAME);
                
                if (storedRegion && availableRegions.includes(storedRegion)) {
                    // Use the stored preference if it's available for this content
                    onSelectRegion(storedRegion);
                } else {
                    // Try to get the user's location from IP
                    try {
                        const response = await fetch('https://ipapi.co/json/');
                        const data = await response.json();
                        const userCountry = data.country_code;
                        
                        if (availableRegions.includes(userCountry)) {
                            onSelectRegion(userCountry);
                            // Save this preference for future visits
                            setCookie(COOKIE_NAME, userCountry);
                        } else if (availableRegions.includes('US')) {
                            onSelectRegion('US');
                        } else {
                            onSelectRegion(availableRegions[0]);
                        }
                    } catch (error) {
                        console.error('Error fetching user location:', error);
                        // Fallback to US or first available
                        if (availableRegions.includes('US')) {
                            onSelectRegion('US');
                        } else {
                            onSelectRegion(availableRegions[0]);
                        }
                    }
                }
            } finally {
                setLoading(false);
            }
        };

        selectRegion();
    }, [availableRegions, onSelectRegion]);

    const handleToggleModal = (e) => {
        e.stopPropagation();
        setShowModal(!showModal);
    };

    const handleSelectRegion = (region) => {
        onSelectRegion(region);
        // Save the user's selection to a cookie
        setCookie(COOKIE_NAME, region);
        setShowModal(false);
    };

    return (
        <div className="flex items-center justify-start sm:justify-end mt-4 sm:mt-0 mb-2">
            <div className="flex items-center">
                <span className="text-gray-200 mr-2 text-base">Switch streaming service location:</span>

                {/* Region selector button */}
                {availableRegions.length > 0 ? (
                    <button
                        onClick={handleToggleModal}
                        className="bg-gray-700 text-white text-xs border border-gray-600 rounded px-2 py-1 flex items-center hover:bg-gray-600 transition-colors"
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="h-4 w-6 bg-gray-600 animate-pulse rounded-sm"></div>
                        ) : (
                            getCountryFlag(selectedRegion)
                        )}
                        <span className="ml-2">{loading ? "Detecting..." : selectedRegion}</span>
                    </button>
                ) : (
                    <span className="text-gray-400 text-xs">No region data available</span>
                )}
            </div>

            {/* Region selector modal */}
            {showModal && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
                    onClick={handleToggleModal}
                >
                    <div
                        className="bg-gray-800 rounded-lg p-4 w-full max-w-md max-h-[80vh] overflow-auto border border-gray-700"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-semibold text-white">Select Region</h3>
                            <button
                                onClick={handleToggleModal}
                                className="text-gray-400 hover:text-white p-1"
                            >
                                <FaTimes />
                            </button>
                        </div>

                        <div className="grid grid-cols-4 sm:grid-cols-6 gap-3">
                            {availableRegions.map((region) => (
                                <button
                                    key={region}
                                    className={`flex flex-col items-center p-2 rounded transition-colors
                                    ${region === selectedRegion
                                            ? 'bg-purple-700 text-white'
                                            : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                                        }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectRegion(region);
                                    }}
                                >
                                    {getCountryFlag(region)}
                                    <span className="mt-1 text-sm">{region}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GlobalRegionSelector;