// hooks/useMovieData.js
import { useState, useEffect } from 'react';

// Define the image base URL for TMDB images
const IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/w500';

const useMovieData = (locationState) => {
	const [movies, setMovies] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchId, setSearchId] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		// Try to get movie data from react-router location state first
		if (locationState && locationState.movieData) {
			processMovieData(locationState.movieData);
		} else {
			// If not in location state, try to get from localStorage
			try {
				const savedData = localStorage.getItem('movieSearchResults');
				if (savedData) {
					const parsedData = JSON.parse(savedData);
					processMovieData(parsedData);
				} else {
					// No data found - show empty state
					setLoading(false);
				}
			} catch (error) {
				console.error('Error parsing saved movie data:', error);
				setLoading(false);
			}
		}
	}, [locationState]);

	// Process the received movie data into the format our components expect
	const processMovieData = (data) => {
		// Only log if we have valid results to process
		if (data && data.results && data.results.length > 0) {
			console.log('Processing movie data:', data);
		}

		// Store the search ID if available
		if (data.id) {
			setSearchId(data.id);
		}

		// Store search query if available
		if (data.transcription) {
			setSearchQuery(data.improved_transcription || data.transcription);
		} else if (data.text_input) {
			setSearchQuery(data.text_input);
		}

		// Check if data has the results array
		if (!data || !data.results || !Array.isArray(data.results) || data.results.length === 0) {
			console.error('Movie data not in expected format or empty:', data);
			setMovies([]);
			setLoading(false);
			return;
		}

		// Transform API response to match our component's expected format
		const formattedMovies = data.results.map((result, index) => {
			const movie = result.movie_data || result;

			// Extract genre names from genres array
			let genreTags = [];
			if (movie.genres && Array.isArray(movie.genres)) {
				// Use the genres array directly from the enhanced backend
				genreTags = movie.genres.map(genre => genre.name);
			} else if (movie.genres && typeof movie.genres === 'string') {
				// Handle string format if that's what's provided
				genreTags = movie.genres.split(',').map(genre => genre.trim());
			}

			// Extract watch providers data
			const watchProviders = result.watch_providers || movie.watch_providers || {};

			return {
				id: movie.id,
				title: movie.original_title && movie.title && movie.original_title !== movie.title
					? `${movie.original_title} (${movie.title})`
					: (movie.title || movie.original_title || 'Unknown Title'),
					
				year: movie.release_date ? new Date(movie.release_date).getFullYear() : 'Unknown',
				rating: movie.vote_average || 0,
				duration: movie.runtime ? `${movie.runtime} min` : 'Unknown',
				description: movie.overview || 'No description available',
				fullDescription: movie.overview || 'No description available', // Same as overview if no separate field
				director: movie.director || 'Unknown Director',
				stars: movie.cast ?
					movie.cast.split(',').map(actor => actor.trim()) :
					[],
				tags: genreTags,
				posterUrl: movie.poster_path ?
					`${IMAGE_BASE_URL}${movie.poster_path}` :
					null,
				backdropUrl: movie.backdrop_path ?
					`${IMAGE_BASE_URL}${movie.backdrop_path}` :
					null,
				streamingOn: movie.providers ?
					Object.keys(movie.providers) :
					(movie.streaming_services ?
						movie.streaming_services.split(',').map(service => service.trim()) :
						[]),
				// Additional fields 
				vote_count: movie.vote_count || 0,
				tagline: movie.tagline || '',
				rank: index + 1, // Add rank for the movie (position in the list)
				similarityScore: result.similarity_score,

				// Pass through additional fields for enhanced display
				original_language: movie.original_language || '',
				original_title: movie.original_title || '',
				budget: movie.budget || 0,
				revenue: movie.revenue || 0,

				// Add watch providers data
				watch_providers: watchProviders,

				// Add contact field if available
				contact: movie.original_contact || 'No contact available'
			};
		});

		setMovies(formattedMovies);
		setLoading(false);
	};

	return {
		movies,
		loading,
		searchId,
		searchQuery
	};
};

export default useMovieData;