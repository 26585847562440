// components/listing/StreamingProviders.jsx
import React from 'react';
import StreamingBadge from './StreamingBadge';

const StreamingProviders = ({ watchProviders, selectedRegion }) => {
    // Get providers for the current region
    const getProvidersForRegion = () => {
        if (!watchProviders || !watchProviders[selectedRegion]) {
            return null;
        }

        const regionData = watchProviders[selectedRegion];
        const providers = [];

        // Add providers in priority order
        if (regionData.flatrate?.length) {
            providers.push(...regionData.flatrate.map(p => ({ ...p, type: 'Subscription' })));
        }

        if (regionData.free?.length) {
            const newProviders = regionData.free
                .filter(p => !providers.some(existing => existing.provider_id === p.provider_id))
                .map(p => ({ ...p, type: 'Free' }));
            providers.push(...newProviders);
        }

        if (regionData.ads?.length) {
            const newProviders = regionData.ads
                .filter(p => !providers.some(existing => existing.provider_id === p.provider_id))
                .map(p => ({ ...p, type: 'Ads' }));
            providers.push(...newProviders);
        }

        if (regionData.rent?.length) {
            const newProviders = regionData.rent
                .filter(p => !providers.some(existing => existing.provider_id === p.provider_id))
                .map(p => ({ ...p, type: 'Rent' }));
            providers.push(...newProviders);
        }

        if (regionData.buy?.length) {
            const newProviders = regionData.buy
                .filter(p => !providers.some(existing => existing.provider_id === p.provider_id))
                .map(p => ({ ...p, type: 'Buy' }));
            providers.push(...newProviders);
        }

        return providers.length > 0 ? providers : null;
    };

    const currentProviders = getProvidersForRegion();
    const availableRegions = watchProviders ? Object.keys(watchProviders) : [];

    return (
        <div>
            {/* Provider badges */}
            <div className="flex flex-wrap gap-2">
                {currentProviders && currentProviders.length > 0 ? (
                    currentProviders.map((provider) => (
                        <StreamingBadge
                            key={provider.provider_id}
                            provider={provider}
                        />
                    ))
                ) : (
                    <span className="text-gray-400 text-sm">
                        {availableRegions.length > 0
                            ? `No streaming information for ${selectedRegion}`
                            : "Streaming information not available"}
                    </span>
                )}
            </div>
        </div>
    );
};

export default StreamingProviders;