// components/listing/MovieDetailsGrid.jsx
import React from 'react';
import { FaDollarSign } from 'react-icons/fa';

const MovieDetailsGrid = ({ movie }) => {
	// Format currency to display in millions/billions
	const formatCurrency = (amount) => {
		if (!amount) return 'N/A';

		// Format as million or billion
		if (amount >= 1000000000) {
			return `$${(amount / 1000000000).toFixed(2)}B`;
		} else if (amount >= 1000000) {
			return `$${(amount / 1000000).toFixed(2)}M`;
		} else {
			return `$${amount.toLocaleString()}`;
		}
	};

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
			<div>
				<h3 className="text-gray-400 text-sm mb-1 sm:text-base sm:mb-2">
					Director
				</h3>
				<p className="text-white text-sm sm:text-base">
					{movie.director || "Information not available"}
				</p>
			</div>

			<div>
				<h3 className="text-gray-400 text-sm mb-1 sm:text-base sm:mb-2">
					Stars
				</h3>
				<p className="text-white text-sm sm:text-base">
					{movie.stars && movie.stars.length > 0
						? movie.stars.join(', ')
						: "Information not available"}
				</p>
			</div>

			{/* Budget and Revenue Section (only shown if values are > 0) */}
			{(movie.budget > 0 || movie.revenue > 0) && (
				<>
					{movie.budget > 0 && (
						<div>
							<h3 className="text-gray-400 text-sm mb-1 sm:text-base sm:mb-2">
								Budget
							</h3>
							<p className="text-white text-sm sm:text-base flex items-center">
								<FaDollarSign className="mr-1 text-green-400" />
								{formatCurrency(movie.budget)}
							</p>
						</div>
					)}

					{movie.revenue > 0 && (
						<div>
							<h3 className="text-gray-400 text-sm mb-1 sm:text-base sm:mb-2">
								Box Office
							</h3>
							<p className="text-white text-sm sm:text-base flex items-center">
								<FaDollarSign className="mr-1 text-green-400" />
								{formatCurrency(movie.revenue)}
							</p>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default MovieDetailsGrid;