import React from 'react';
import RatingBadge from './RatingBadge';
import MoviePoster from './MoviePoster';
import StreamingProviders from './StreamingProviders';
import MovieFeedback from './MovieFeedback';
import { getLanguageFlag } from '../../utils/LanguageFlags';

const MovieCard = ({ movie, isSelected, onClick, promptId, rank, selectedRegion }) => {
	return (
		<div
			className={`
        bg-gray-800 rounded-lg overflow-hidden mb-4 border 
        transition-all duration-300
        ${isSelected
					? 'border-purple-500 shadow-lg shadow-purple-500/20 transform scale-[1.02]'
					: 'border-gray-700 hover:border-gray-600'
				}
      `}
		>
			<div className="flex flex-col sm:flex-row">
				{/* Movie poster - Clickable */}
				<MoviePoster
					posterUrl={movie.posterUrl}
					title={movie.title}
					onClick={() => onClick(movie.id)}
				/>

				{/* Movie info & controls */}
				<div className="p-4 sm:pl-6 flex-grow flex flex-col justify-between">
					{/* Movie header */}
					<div>
						<div className="flex justify-between items-start">
							{/* Clickable title */}
							<h3
								onClick={() => onClick(movie.id)}
								className="text-lg font-semibold flex items-center cursor-pointer hover:text-purple-400 transition-colors"
							>
								{movie.title} {movie.year !== 'Unknown' && `(${movie.year})`}
								{movie.original_language && getLanguageFlag(movie.original_language)}
							</h3>
							<RatingBadge rating={movie.rating} />
						</div>

						<div className="flex items-center text-sm text-gray-400 mt-1">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>{movie.duration}</span>
						</div>

						<p className="text-gray-300 mt-2 line-clamp-2">
							{movie.description}
						</p>
					</div>

					{/* Bottom section with streaming options and ratings */}
					<div className="mt-auto pt-4">
						<div className="flex flex-col sm:flex-row justify-between gap-4">
							{/* Left Section: Streaming options */}
							<div>
								<div className="flex items-center mb-2">
									<h3 className="text-gray-100 text-sm sm:text-base mr-2">
										Available On:
									</h3>
								</div>

								{/* Streaming providers component - using the global selected region */}
								<StreamingProviders
									watchProviders={movie.watch_providers}
									selectedRegion={selectedRegion}
								/>
							</div>

							{/* Right Section: Rating feedback */}
							<div className="flex items-center sm:self-end mt-4 sm:mt-0">
								<MovieFeedback
									movieId={movie.id}
									promptId={promptId}
									rank={rank}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MovieCard;