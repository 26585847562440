// components/listing/MovieFeedback.jsx
import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const MovieFeedback = ({ movieId, promptId, rank }) => {
    const [promptRated, setPromptRated] = useState(false);
    const [promptRating, setPromptRating] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const apiUrl = '/api';

    // Handle prompt rating (thumbs up/down)
    const handlePromptRating = async (score, e) => {
        if (promptRated) return;
        e.stopPropagation(); // Prevent propagation to parent elements

        try {
            setPromptRating(score);
            setPromptRated(true);

            const payload = {
                id_search: Number(promptId),
                rank: Number(rank),
                score: Number(score),
                id_movie: Number(movieId),
            };

            const response = await fetch(`${apiUrl}/score/movie`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setFeedbackMessage("Thanks for your feedback!");
                setTimeout(() => setFeedbackMessage(''), 3000);
            } else {
                setPromptRated(false);
                setPromptRating(null);
                setFeedbackMessage("Error submitting rating. Please try again.");
                setTimeout(() => setFeedbackMessage(''), 3000);
            }
        } catch (error) {
            console.error("Error during prompt rating:", error);
            setPromptRated(false);
            setPromptRating(null);
            setFeedbackMessage("Error submitting rating. Please try again.");
            setTimeout(() => setFeedbackMessage(''), 3000);
        }
    };

    return (
        <div>
            <p className="text-gray-200 text-sm mb-2 mt-4">Good match?</p>
            <div className="flex gap-2">
                <button
                    onClick={(e) => handlePromptRating(1, e)}
                    disabled={promptRated}
                    className={`flex items-center gap-1 px-3 py-1 rounded 
            ${promptRating === 1 ? 'bg-green-600' : 'bg-gray-700 hover:bg-gray-600'} 
            text-white transition-colors
            ${promptRated && promptRating !== 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    <FaThumbsUp className="w-4 h-4" />
                    Yes
                </button>
                <button
                    onClick={(e) => handlePromptRating(0, e)}
                    disabled={promptRated}
                    className={`flex items-center gap-1 px-3 py-1 rounded 
            ${promptRating === 0 ? 'bg-red-600' : 'bg-gray-700 hover:bg-gray-600'} 
            text-white transition-colors
            ${promptRated && promptRating !== 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    <FaThumbsDown className="w-4 h-4" />
                    No
                </button>
            </div>

            {/* Feedback message */}
            {feedbackMessage && (
                <div className="mt-2 text-green-400 text-sm">{feedbackMessage}</div>
            )}
        </div>
    );
};

export default MovieFeedback;