// src/components/admin/AdminLogin.jsx
import React, { useState } from 'react';
import { authService } from '../../services/api';

const AdminLogin = ({ onLoginSuccess }) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError('');

		try {
			const response = await authService.login(username, password);

			if (response.isAdmin) {
				onLoginSuccess();
			} else {
				setError('You do not have admin privileges');
				authService.logout();
			}
		} catch (error) {
			console.error('Login error:', error);
			setError(error.response?.data?.error || 'Invalid username or password');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center mt-20 bg-gray-900 p-4">
			{/* Login modal/card */}
			<div className="w-full max-w-md bg-gray-800 rounded-lg shadow-lg  overflow-hidden">
				<div className="p-6">
					<h2 className="text-center text-xl font-semibold text-white mb-6">
						Admin Login
					</h2>

					{error && (
						<div className="bg-red-900 border border-red-800 text-white px-4 py-3 rounded relative mb-6" role="alert">
							<span className="block sm:inline">{error}</span>
						</div>
					)}

					<form onSubmit={handleLogin}>
						<div className="space-y-4">
							<input
								id="username"
								name="username"
								type="text"
								required
								className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md placeholder-gray-500 text-white bg-gray-700 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm"
								placeholder="FIRECRAWL_API_KEY"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>

							<input
								id="password"
								name="password"
								type="password"
								required
								className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md placeholder-gray-500 text-white bg-gray-700 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<button
							type="submit"
							disabled={loading}
							className={`w-full mt-6 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white ${loading ? 'bg-indigo-700' : 'bg-indigo-600 hover:bg-indigo-700'
								} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors`}
						>
							{loading ? 'Logging in...' : 'Login'}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AdminLogin;