//src/services/api.js
// API service to interact with the backend

import axios from 'axios';
import Cookies from 'js-cookie';

// API endpoint (relative path for security)
const apiUrl = '/api';

// Create axios instance with default config
const api = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add request interceptor to add auth token if available
api.interceptors.request.use(
	(config) => {
		const token = Cookies.get('auth_token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

// Auth services
export const authService = {
	login: async (username, password) => {
		const response = await api.post('/auth/login', { username, password });
		if (response.data.token) {
			// Note: HttpOnly cookies can only be set by the server
			// For client-side JavaScript, we can only set non-HttpOnly cookies
			Cookies.set('auth_token', response.data.token, {
				secure: true, // Always use secure cookies
				sameSite: 'strict',
				expires: 7 // 7 days
			});
		}
		return response.data;
	},

	register: async (userData) => {
		return api.post('/auth/register', userData);
	},

	logout: () => {
		Cookies.remove('auth_token');
		localStorage.removeItem('admin_token_version');
	},

	isAuthenticated: () => {
		return !!Cookies.get('auth_token');
	},

	isAdmin: async () => {
		try {
			// Make an authenticated request to a protected admin endpoint
			// This will only succeed if the server validates the token as an admin token
			const response = await api.get('/admin/verify');

			// Check if the response is successful and contains valid token version
			if (response.status === 200) {
				// Store the token version for future reference
				if (response.data && response.data.tokenVersion) {
					localStorage.setItem('admin_token_version', response.data.tokenVersion.toString());
				}
				return true;
			}
			return false;
		} catch (error) {
			// If the request fails, the user is not an admin
			localStorage.removeItem('admin_token_version');
			return false;
		}
	}
};

// Movie services
export const movieService = {
	getMovieRecommendations: async (textQuery, audioBlob) => {
		// For text queries, use dedicated text endpoint
		if (textQuery && !audioBlob) {
			return api.post('/movies/text', { text_input: textQuery });
		}

		// For audio, use FormData with the main endpoint
		const formData = new FormData();
		if (textQuery) {
			formData.append('text_input', textQuery);
		}
		if (audioBlob) {
			formData.append('file', audioBlob, 'recording.mp3');
		}

		return api.post('/movies', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	getMovieById: async (movieId) => {
		const response = await api.get(`/movies/${movieId}`);
		return response.data;
	},

	// do you live the movie recommended yes or not?
	rateMovie: async (movieId, rating, promptId, rank) => {
		return api.post('/score/movie', {
			id_search: Number(promptId),
			rank: rank,
			score: rating,
			id_movie: movieId
		});
	},

	// do you live the movie in general? heart
	rateRecommendation: async (movie_id) => {
		return api.post('/score/recommendation', { movie_id: movie_id});
	},

	rateTranscription: async (searchId, rating) => {
		return api.post('/score/transcription', { search_id: searchId, score: rating });
	}
};

// Admin services
export const adminService = {
	getMovieSearches: async () => {
		const response = await api.get('/admin/movies_searches');
		return response.data || [];
	},

	getMovieById: async (movieId) => {
		const response = await api.get(`/admin/movie_by_id/${movieId}`);
		return response.data;
	},

	getMovieScore: async (searchId, movieId) => {
		const response = await api.get(`/admin/movies_score/${searchId}/${movieId}`);
		return response.data;
	}
};

export default api;