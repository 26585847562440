// components/common/EmptyState.jsx
import React from 'react';

const EmptyState = ({ title, message }) => {
  return (
    <div className="bg-gray-800 rounded-lg p-8 text-center border border-gray-700">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-400">{message}</p>
    </div>
  );
};

EmptyState.defaultProps = {
  title: "No results found",
  message: "Try adjusting your search query or try a different one."
};

export default EmptyState;